@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .paused {
    animation-play-state: paused;
  }
}


/* Lenis - scroll */

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}
